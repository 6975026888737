import React, { useEffect, useRef, useState } from 'react';
import { Box, Fade, Typography } from '@material-ui/core';
import ArrowButton from '../../atoms/arrow-button/arrowButton';
import { findTarget } from '../../../utils/findTarget';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from './carrusel3.module.scss';
import './carrusel3.scss';
import { generateSlug } from '../../../utils/generateSlug';

export interface ICarrusel3Props {
    title?: string;
    description?: any;
    cardsList: [
        {
            image: string;
            title: string;
            description: string;
            redirect: string;
        },
    ];
}

const Carrusel3 = ({ title, description, cardsList }: ICarrusel3Props) => {
    const [isVisible, setIsVisible] = useState(false);
    const Carrusel3Ref = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                setIsVisible(entries[0].isIntersecting);
            }
        });
        if (Carrusel3Ref.current) {
            observer.observe(Carrusel3Ref.current);
        }
        return () => observer.disconnect();
    }, []);

    const settings = {
        dots: false,
        infinite: true,
        slidesToScroll: 1,
        autoplay: true,
        variableWidth: true,
        initialSlide: 0,
        nextArrow: <ArrowButton />,
        prevArrow: <ArrowButton />,
    };

    return (
        <Fade in={isVisible} timeout={1000}>
            <section ref={Carrusel3Ref}>
                <Box className={`${styles.o_carrusel3} o_carrusel3`}>
                    <Box className={styles.o_carrusel3__intro}>
                        {description && (
                            <Typography variant='body1' className="a-home__desc">{description}</Typography>
                        )}
                        {title && <h2 className="a-home__h2">{title}</h2>}
                    </Box>
                    <Slider {...settings} accessibility>
                        {cardsList.map((card, key) => (
                            <div
                                key={`carrusel3-${key}`}
                                className={styles.o_carrusel3__card}
                            >
                                <a
                                    className={`${
                                        styles.o_carrusel3__slideContainer
                                    } gtm_click_carrusel_comfama_home_body_espacios_${generateSlug(
                                        card.title,
                                    )}`}
                                    href={card.redirect}
                                    target={findTarget(card.redirect)}
                                    rel={
                                        findTarget(card.redirect) === '_blank'
                                            ? 'noreferrer follow'
                                            : 'referrer follow'
                                    }
                                >
                                    {card.image.includes('//videos') ? (
                                        <video
                                            playsInline
                                            controls={false}
                                            autoPlay
                                            muted
                                            loop
                                            className={
                                                styles.o_carrusel3__video
                                            }
                                        >
                                            <source
                                                src={card.image}
                                                type="video/webm"
                                            />
                                            <source
                                                src={card.image}
                                                type="video/mp4"
                                            />
                                            Tu navegador no soporta video HTML5.
                                        </video>
                                    ) : (
                                        <img
                                            loading="eager"
                                            src={card.image}
                                            alt=""
                                            className={styles.o_carrusel3__img}
                                        />
                                    )}
                                    <Box className="o_carrusel3__info">
                                        <Typography variant="h3" className={styles.o_carrusel3__h3}>
                                            {card.title}
                                        </Typography>
                                        <p className={styles.o_carrusel3__p}>
                                            {card.description}
                                        </p>
                                    </Box>
                                </a>
                            </div>
                        ))}
                    </Slider>
                </Box>
            </section>
        </Fade>
    );
};

export default Carrusel3;
