import React, { useEffect, useRef, useState } from 'react';
import { Box, Card, Fade, Typography } from '@material-ui/core';
import Slider from 'react-slick';
import ArrowButton from '../../atoms/arrow-button/arrowButton';
import useScreenSize from '../../../hooks/useScreenSize';
import { findTarget } from '../../../utils/findTarget';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from './carrusel4.module.scss';
import './carrusel4.scss';
import { generateSlug } from '../../../utils/generateSlug';

export interface ICarrusel4Props {
    title?: string;
    description?: any;
    cardsList: [
        {
            image: string;
            title: string;
            bg: string;
            color: string;
            description: string;
            redirect: string;
        },
    ];
}

const Carrusel4 = ({ title, description, cardsList }: ICarrusel4Props) => {
    const screenSize = useScreenSize();
    const [isVisible, setIsVisible] = useState(false);
    const Carrusel4Ref = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                setIsVisible(entries[0].isIntersecting);
            }
        });
        if (Carrusel4Ref.current) {
            observer.observe(Carrusel4Ref.current);
        }
        return () => observer.disconnect();
    }, []);

    const settings = {
        dots: false,
        infinite: false,
        slidesToShow: screenSize / 450,
        slidesToScroll: 1,
        nextArrow: <ArrowButton />,
        prevArrow: <ArrowButton />,
        responsive: [
            {
                breakpoint: 570,
                settings: {
                    slidesToShow: screenSize / 318,
                    dots: true,
                },
            },
        ],
    };

    return (
        <Fade in={isVisible} timeout={1000}>
            <section ref={Carrusel4Ref}>
                <Box className={`${styles.o_carrusel4} o_carrusel4`}>
                    <Box className={styles.o_carrusel4__intro}>
                        {description && (
                            <Typography variant='body1' className="a-home__desc">{description}</Typography>
                        )}
                        {title && <h2 className="a-home__h2">{title}</h2>}
                    </Box>
                    <Slider
                        {...settings}
                        className={styles.o_carrusel4__slider}
                    >
                        {cardsList.map((card, key) => (
                            <Card
                                key={`carrusel4-${key}`}
                                className={styles.o_carrusel4__card}
                            >
                                <div
                                    className={
                                        styles.o_carrusel4__cardContainer
                                    }
                                    style={{
                                        color: card.color,
                                        background: card.bg,
                                    }}
                                >
                                    <img
                                        loading="eager"
                                        src={card.image}
                                        alt=""
                                        className={styles.o_carrusel4__img}
                                    />
                                    <Typography
                                        variant="h3"
                                        component="div"
                                        className={styles.o_carrusel4__h3}
                                    >
                                        {card.title}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        component="p"
                                        className={styles.o_carrusel4__p}
                                    >
                                        {card.description}
                                    </Typography>
                                    <a
                                        href={card.redirect}
                                        target={findTarget(card.redirect)}
                                        rel={
                                            findTarget(card.redirect) ===
                                            '_blank'
                                                ? 'noreferrer follow'
                                                : 'referrer follow'
                                        }
                                        className={`${
                                            styles.o_carrusel4__link
                                        } gtm_click_ecard_comfama_home_body_${generateSlug(
                                            card.title,
                                        )}`}
                                        style={{
                                            color: card.color,
                                        }}
                                    >
                                        {card.title}
                                    </a>
                                </div>
                            </Card>
                        ))}
                    </Slider>
                </Box>
            </section>
        </Fade>
    );
};

export default Carrusel4;
