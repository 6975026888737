import React from 'react'
import { ReferenciaContenido } from '../../../interfaces/ReferenciaContenido'
import GridCardsSection from '../../organisms/grid-cards-section/girdCardsSection'
import CardColumns from '../../molecules/card-colums/cardColumns'
import ContentLeftRight from '../../molecules/content-left-right/contentLeftRight'
import Gallery from '../../organisms/gallery/gallery'
import GalleryExtended from '../../organisms/gallery-extended/galleryExtended'
import VideoLink from '../../molecules/video-link/videoLink'
import SubsectionContainer from '../../molecules/subsection-container/subsectionContainer'
import DiscoverAppSection from '../../molecules/discover-app/discoverAppSection'
import DownloadsList from '../../molecules/downloads-list/downloadsList'
import OfficesList from '../../molecules/offices-list/officesList'
import GalleryCards from '../../organisms/gallery-cards/galleryCards'
import Richtext from '../../molecules/richtext/richtext'
import VideoService from '../../organisms/video-service/videoService'
import FrequentlyAskedQuestions from '../../molecules/frequent-questions/frequent-questions'
import ArticleContentDescription from '../../molecules/article-content-description/articleContentDescription'
import SpotifyListen from '../../atoms/spotify-listen/spotifyListen'
import Readtime from '../../atoms/readtime/readTime'
import PriceInformation from '../../organisms/price-information/priceInformation'
import History from '../../organisms/history/history'
import SectionContainer from '../../molecules/section-container/sectionContainer'
import Axis from '../../organisms/axis/axis'
import Carousel from '../../organisms/carousel/carousel'
import ContentRotator from '../../molecules/content-rotator/contentRotator'
import CoursesSection from '../../organisms/courses-section/coursesSection'
import ResultsCardList from '../../organisms/results-card-list/resultsCardList'
import HistoryLink from '../../organisms/history-link/historyLink'
import Maps from '../../organisms/maps/maps'
import MapSedeInfo from '../../organisms/map-sede-info/mapSedeInfo'
import CircularSlider from '../../organisms/circular-slider/circularSlider'
import SimpleDescription from '../../molecules/simple-description/simpleDescription'
import DocumentsPlayer from "../../molecules/documents-player/documentsPlayer"
import VideosPlayer from '../../molecules/videos-player/videosPlayer'
import AudiosPlayer from "../../molecules/audios-player/audiosPlayer"
import ImagesSwitcher from '../../organisms/images-switcher/imagesSwitcher'
import ScrollParallax from '../../organisms/scroll-parallax/scrollParallax'
import Redirection from '../../atoms/redirection/redirection'
import Map from '../../organisms/map/map'
import Featured from "../../molecules/featured/featured";
import Infographic from "../../organisms/infographic/infographic";
import SimpleCards from "../../molecules/simple-cards/simpleCards";
import PressNews from "../../organisms/press-news/pressNews";
import MultiGallery from "../../organisms/multi-gallery/multiGallery";
import VideosDownloader from "../../molecules/videos-downloader/videosDownloader"
import CarouselAlgoritmo from '../../organisms/carousel-algoritmo/carouselAlgoritmo'
import HoverImages from '../../organisms/hover-images/hoverImages'
import SmallCards from '../../organisms/small-cards/smallCards'
import FormLibranzas from '../../organisms/form-libranzas/formLibranzas'
import HistoryLinkClaustro from '../../organisms/history-link-claustro/historyLinkClaustro'
import BannerInforme from '../../organisms/banner-informe/bannerInforme'
import DescriptionInforme from '../../organisms/description-informe/descriptionInforme'
import Journey from '../../organisms/journey/journey'
import Projects from '../../organisms/projects/projects'
import MapCards from '../../molecules/map-cards/mapCards'
import Linktree from '../../organisms/linktree/linktree'
import TreesCards from '../../organisms/trees-cards/treesCards'
import BodyAsamblea2022 from '../../organisms/body-asamblea2022/bodyAsamblea2022'
import CategoriesMenu from '../../molecules/categories-menu/categoriesMenu'
import RequirementsContainer from '../../molecules/requirements-container/requirementsContainer'
import StepContainer from '../../molecules/step-container/stepContainer'
import CardButtonContainer from '../../organisms/card-button-container/cardButtonContainer'
import ImageCardContainer from '../../organisms/image-card-container/imageCardContainer'
import HowItWorksContainer from '../../organisms/how-it-works-container/howItWorksContainer'
import ColumnItems from '../../organisms/column-items/columnItems'
import ImageBulletInfoContainer from '../../organisms/image-bullet-info-container/imageBulletInfoContainer'
import VideoDescription from '../../organisms/video-description/videoDescription'
import categoriesMenuAdapter from '../../molecules/categories-menu/adapter/categories-menu.adapter'
import requirementsContainerAdapter from '../../molecules/requirements-container/adapter/requirements-container.adapter'
import stepContainerAdapter from '../../molecules/step-container/adapter/step-container.adapter'
import cardButtonContainerAdapter from '../../organisms/card-button-container/adapter/card-button-container.adapter'
import imageCardContainerAdapter from '../../organisms/image-card-container/adapter/image-card-container.adapter'
import howItWorksContainerAdapter from '../../organisms/how-it-works-container/adapter/how-it-works.adapter'
import columnItemsAdapter from '../../organisms/column-items/adapter/column-items.adapter'
import imageBulletInfoContainerAdapter from '../../organisms/image-bullet-info-container/adapter/image-bullet-info-container.adapter'
import videoDescriptionAdapter from '../../organisms/video-description/adapter/video-description.adapter'
import BodyDientelandia from '../../organisms/body-dientelandia/bodyDientelandia'
import FormAbrazosQueEmocionan from '../../organisms/body-form-abrazos-que-emocionan'
import CarouselRates from "../../molecules/carousel-rates/carousel-rates"
import Experiences from '../../organisms/experiences'
import CardYouTube from '../../organisms/card-youtube/cardYouTube'
import cardYouTubeAdapter from '../../organisms/card-youtube/adapter/cardYouTube.adapter'
import CardsOverflow from '../../organisms/cards-overflow/cardsOverflow'
import cardsOverflowAdapter from '../../organisms/cards-overflow/adapter/cardsOverflow.adapter'
import Stories from '../../organisms/stories/stories'
import storiesAdapter from '../../organisms/stories/adapter/stories.adapter'
import CardsConocer from '../../organisms/cards-conocer/cardsConocer'
import cardsConocerAdapter from '../../organisms/cards-conocer/adapter/cardsConocer.adapter'
import Carrusel1 from '../../organisms/carrusel1/carrusel1'
import carrusel1Adapter from '../../organisms/carrusel1/adapter/carrusel1.adapter'
import Banner2 from '../../organisms/banner2/banner2'
import banner2Adapter from '../../organisms/banner2/adapter/banner2.adapter'
import Carrusel2 from '../../organisms/carrusel2/carrusel2'
import carrusel2Adapter from '../../organisms/carrusel2/adapter/carrusel2.adapter'
import CardsVideo from '../../organisms/cards-video/cardsVideo'
import cardsVideoAdapter from '../../organisms/cards-video/adapter/cardsVideo.adapter'
import Warning from '../../molecules/warning/warning'
import warningAdapter from '../../molecules/warning/adapter/warning.adapter'
import Portfolio from '../../organisms/portfolio/portfolio'
import portfolioAdapter from '../../organisms/portfolio/adapter/portfolio.adapter'
import Carrusel3 from '../../organisms/carrusel3/carrusel3'
import carrusel3Adapter from '../../organisms/carrusel3/adapter/carrusel3.adapter'
import CarruselCategorias from '../../organisms/carrusel-categorias/carruselCategorias'
import carruselCategoriasAdapter from '../../organisms/carrusel-categorias/adapter/carruselCategorias.adapter'
import AcordeonTabs from '../../organisms/acordeon-tabs/acordeonTabs'
import acordeonTabsAdapter from '../../organisms/acordeon-tabs/adapter/acordeonTabs.adapter'
import DestacadoFoto from '../../organisms/destacado-foto/destacadoFoto'
import destacadoFotoAdapter from '../../organisms/destacado-foto/adapter/destacadoFoto.adapter'
import Carrusel4 from '../../organisms/carrusel4/carrusel4'
import carrusel4Adapter from '../../organisms/carrusel4/adapter/carrusel4.adapter'
import DestacadoVideo from '../../organisms/destacado-video/destacadoVideo'
import destacadoVideoAdapter from '../../organisms/destacado-video/adapter/destacadoVideo.adapter'
import Hero from '../../organisms/hero/hero'
import heroAdapter from '../../organisms/hero/adapter/hero.adapter'

interface LibraryInterface {
   content: ReferenciaContenido;
   categoryName: string;
}

const LibraryComponents = (props: LibraryInterface) => {

   const refContent = props.content;
   const categoryName = props.categoryName;
 
   switch (props.content.tipoComponente) {
      case 'AgregadosCategoria':
         return <GridCardsSection {...refContent} nombreCategoria={categoryName} />
      case 'InformacionInstitucional':
         return <CardColumns {...refContent} nombreCategoria={categoryName} />
      case 'Wellness':
         return <ContentLeftRight {...refContent} nombreCategoria={categoryName} />
      case 'Galeria':
         return <Gallery {...refContent} nombreCategoria={categoryName} />
      case 'GaleriaExtendida':
         return <GalleryExtended {...refContent} nombreCategoria={categoryName} />
      case 'Destacados':
         return <VideoLink {...refContent} nombreCategoria={categoryName} />
      case 'DestacadoParaTi':
         return <GalleryCards {...refContent} nombreCategoria={categoryName} />
      case 'Beneficios':
         return <SubsectionContainer {...refContent} nombreCategoria={categoryName} />
      case 'Publicidad':
         return <DiscoverAppSection {...refContent} nombreCategoria={categoryName} />
      case 'DocumentosInteres':
         return <DownloadsList {...refContent} nombreCategoria={categoryName} />
      case 'NuestrasSedes':
         return <OfficesList {...refContent} nombreCategoria={categoryName} />
      case 'ContenidoEnriquecido':
         return <Richtext {...refContent} nombreCategoria={categoryName} />
      case 'VideoService':
         return <VideoService {...refContent} nombreCategoria={categoryName} />
      case 'PreguntasFrecuentes':
         return <FrequentlyAskedQuestions {...refContent} nombreCategoria={categoryName} />
      case 'ContenidoEnriquecidoArticulo':
         return <ArticleContentDescription {...refContent} nombreCategoria={categoryName} />
      case 'Spotify':
         return <SpotifyListen  {...refContent} nombreCategoria={categoryName} />
      case 'TiempoLectura':
         return <Readtime   {...refContent} nombreCategoria={categoryName} />
      case 'Tarifas':
         return <PriceInformation  {...refContent} nombreCategoria={categoryName} />
      case 'Historia':
         return <History {...refContent} nombreCategoria={categoryName} />
      case 'SectionContainer':
         return <SectionContainer {...refContent} nombreCategoria={categoryName} />
      case 'Ejes':
         return <Axis {...refContent} nombreCategoria={categoryName} />
      case 'GaleriaPrincipal':
         return <Carousel {...refContent} nombreCategoria={categoryName} />
      case 'RotadorPrincipal':
         return <ContentRotator {...refContent} nombreCategoria={categoryName} />
      case 'SeccionCursos':
         return <CoursesSection {...refContent} nombreCategoria={categoryName} />
      case 'Cartas':
         return <ResultsCardList {...refContent} nombreCategoria={categoryName} />
      case 'LineaTiempo':
         return <HistoryLink {...refContent} nombreCategoria={categoryName} />
      case 'Mapa':
         return <Maps {...refContent} nombreCategoria={categoryName} />
      case "MapaSedeInfo":
         return <MapSedeInfo {...refContent} nombreCategoria={categoryName} />;
      case 'SliderCircular':
         return <CircularSlider {...refContent} nombreCategoria={categoryName} />
      case 'Descripcion':
         return <SimpleDescription {...refContent} nombreCategoria={categoryName} />
      case "RotadorDocumentos":
         return <DocumentsPlayer {...refContent} nombreCategoria={categoryName} />;
      case "ReproductorVideos":
         return <VideosPlayer {...refContent} nombreCategoria={categoryName} />;
      case "ReproductorAudios":
         return <AudiosPlayer {...refContent} nombreCategoria={categoryName} />;
      case "SwitcherImagenes":
         return <ImagesSwitcher {...refContent} nombreCategoria={categoryName} />;
      case "ScrollParallax":
         return <ScrollParallax {...refContent} nombreCategoria={categoryName} />;
      case "Redireccion":
         return <Redirection {...refContent} nombreCategoria={categoryName} />;
      case "MapaSimple":
         return <Map {...refContent} nombreCategoria={categoryName}/>;
      case "MapaSimpleGrande":
         return <Map {...refContent} nombreCategoria={categoryName} big/>;
      case "DestacadosHome":
         return <Featured {...refContent} nombreCategoria={categoryName} />;
      case "Infografia":
         return <Infographic {...refContent} nombreCategoria={categoryName} />;
      case "Tarjetas":
         return <SimpleCards {...refContent} nombreCategoria={categoryName} />;
      case "CarouselAlgoritmo":
         return <CarouselAlgoritmo {...refContent} nombreCategoria={categoryName} />
      case "ImagenesHover":
         return <HoverImages {...refContent} nombreCategoria={categoryName} />
      case "SmallCards":
         return <SmallCards {...refContent} nombreCategoria={categoryName} />
      case "FormLibranzas":
         return <FormLibranzas {...refContent} nombreCategoria={categoryName} />;
      case 'LineaTiempoClaustro':
         return <HistoryLinkClaustro {...refContent} nombreCategoria={categoryName} />
      case 'BannerInforme':
         return <BannerInforme {...refContent} nombreCategoria={categoryName} />
      case 'DescriptionInforme':
         return <DescriptionInforme {...refContent} nombreCategoria={categoryName} />
      case "NotasPrensa":
         return <PressNews {...refContent} nombreCategoria={categoryName} />;
      case "GaleriaMultiple":
         return <MultiGallery {...refContent} nombreCategoria={categoryName} />;
      case "DescargarVideos":
         return <VideosDownloader {...refContent} nombreCategoria={categoryName}/>;
      case 'Journey' :
         return <Journey { ...refContent } nombreCategoria={ categoryName } />
      case 'Proyectos' :
         return <Projects { ...refContent } nombreCategoria={ categoryName } />
      case 'MapaCards' :
         return <MapCards { ...refContent } nombreCategoria={ categoryName } />
      case 'Linktree' :
         return <Linktree { ...refContent } nombreCategoria={ categoryName } />
      case 'ArbolesTarjetas' :
         return <TreesCards { ...refContent } nombreCategoria={ categoryName } />
      case "BodyAsamblea2022":
         return <BodyAsamblea2022 {...refContent} nombreCategoria={categoryName} />;
      case "MenuCategorias":
         return <CategoriesMenu {...categoriesMenuAdapter(refContent)} />;
      case "CreditosRequisitos":
         return <RequirementsContainer {...requirementsContainerAdapter(refContent)} />;
      case "CreditoPasoApaso":
         return <StepContainer  {...stepContainerAdapter(refContent)} />;
      case "SmallCenteredCards":
         return <CardButtonContainer {...cardButtonContainerAdapter(refContent)} />;
		case "ImageCardsContainer":
         return <ImageCardContainer {...imageCardContainerAdapter(refContent)} />;
      case "ComoFunciona":
         return <HowItWorksContainer {...howItWorksContainerAdapter(refContent)} />;
      case "ItemsColumna":
         return <ColumnItems {...columnItemsAdapter(refContent)} />;
      case "ContenedorImagenBullet":
         return <ImageBulletInfoContainer {...imageBulletInfoContainerAdapter(refContent)} />;
      case "VideoDescripcion":
         return <VideoDescription {...videoDescriptionAdapter(refContent)} />;
      case "BodyDientelandia":
         return <BodyDientelandia {...refContent} nombreCategoria={categoryName} />;
      case "Planes":
         return <CarouselRates {...refContent} nombreCategoria={categoryName} />;
      case "FormAbrazosQueEmocionan":
         return <FormAbrazosQueEmocionan />;
      case "Experiencias":
         return <Experiences {...refContent} nombreCategoria={categoryName} />;
      case 'VideoDescripcion':
         return <CardYouTube {...cardYouTubeAdapter(refContent)} />;
      case 'CardsOverflow':
         return <CardsOverflow {...cardsOverflowAdapter(refContent)} />;
      case 'CardsHistorias':
         return <Stories {...storiesAdapter(refContent)} />;
      case 'CardsConocer':
         return <CardsConocer {...cardsConocerAdapter(refContent)} />;
      case 'Carrusel1':
         return <Carrusel1 {...carrusel1Adapter(refContent)} />;
      case 'Banner2':
         return <Banner2 {...banner2Adapter(refContent)} />;
      case 'Carrusel2':
         return <Carrusel2 {...carrusel2Adapter(refContent)} />;
      case 'CardsVideos':
         return <CardsVideo {...cardsVideoAdapter(refContent)} />;
      case 'Warning':
         return <Warning {...warningAdapter(refContent)} />;
      case 'Portafolio':
         return <Portfolio {...portfolioAdapter(refContent)} />;
      case 'Carrusel3':
         return <Carrusel3 {...carrusel3Adapter(refContent)} />;
      case 'CarruselCategorias':
         return <CarruselCategorias {...carruselCategoriasAdapter(refContent)} />;
      case 'PreguntasFrecuentes2':
         return <AcordeonTabs {...acordeonTabsAdapter(refContent)} />;
      case 'DestacadoFoto':
         return <DestacadoFoto {...destacadoFotoAdapter(refContent)} />;
      case 'Carrusel4':
         return <Carrusel4 {...carrusel4Adapter(refContent)} />;
      case 'DestacadoVideo':
         return <DestacadoVideo {...destacadoVideoAdapter(refContent)} />;
      case 'Hero':
         return <Hero {...heroAdapter(refContent)} />;
   }

   return (<></>)
}

export default LibraryComponents;
