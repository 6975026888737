import React, { useEffect, useRef, useState } from 'react';
import { Box, Container, Fade, Grid, Typography } from '@material-ui/core';
import Button2 from '../../atoms/button2/button2';
import styles from './destacadoFoto.module.scss';

export interface IDestacadoFotoProps {
    title: string;
    desc: any;
    img: string;
    btnLabel: string;
    btnRedirect: string;
}

const DestacadoFoto = ({
    title,
    desc,
    img,
    btnLabel,
    btnRedirect,
}: IDestacadoFotoProps) => {
    const [isVisible, setIsVisible] = useState(false);
    const DestacFotoRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                setIsVisible(entries[0].isIntersecting);
            }
        });
        if (DestacFotoRef.current) {
            observer.observe(DestacFotoRef.current);
        }
        return () => observer.disconnect();
    }, []);

    return (
        <Fade in={isVisible} timeout={1000}>
            <Container
                ref={DestacFotoRef}
                component="section"
                className={styles.o_destacado_foto}
            >
                <Grid
                    container
                    lg={10}
                    xs={12}
                    className={styles.o_destacado_foto__container}
                >
                    <Grid
                        item
                        sm={4}
                        xs={12}
                        className={styles.o_destacado_foto__left}
                    >
                        <img src={img} alt="" />
                    </Grid>
                    <Grid
                        item
                        sm={6}
                        xs={12}
                        className={styles.o_destacado_foto__right}
                    >
                        <Typography variant="h2">{title}</Typography>
                        <Typography variant="body1">{desc}</Typography>
                        <Box className={styles.o_destacado_foto__btn}>
                            <Button2 label={btnLabel} redirect={btnRedirect} classname="gtm_click_button_comfama_home_body_experiencias" />
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Fade>
    );
};

export default DestacadoFoto;
