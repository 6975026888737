import React, { useEffect, useRef, useState } from 'react';
import { Box, Card, Fade, Typography } from '@material-ui/core';
import Slider from 'react-slick';
import Button2 from '../../atoms/button2/button2';
import ArrowButton from '../../atoms/arrow-button/arrowButton';
import useScreenSize from '../../../hooks/useScreenSize';
import extractUniqueKeyValues from '../../../utils/arrayWithUniqueValues';
import { findTarget } from '../../../utils/findTarget';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from './carruselCategorias.module.scss';
import './carruselCategorias.scss';
import { generateSlug } from '../../../utils/generateSlug';

export interface ICarruselCategoriasProps {
    title?: string;
    description?: any;
    btnTitle: string;
    btnRedirect: string;
    cardsList: [
        {
            image: string;
            title: string;
            desc: string;
            btnRedirect: string;
            btnTitle: string;
            cat: string;
            bgColor: string;
            color: string;
        },
    ];
}

const CarruselCat = ({
    title,
    description,
    btnTitle,
    btnRedirect,
    cardsList,
}: ICarruselCategoriasProps) => {
    const [tabs, setTabs] = useState([]);
    const [selectedCategoryList, setSelectedCategoryList] = useState([]);
    const [selectedCat, setSelectedCat] = useState({ index: 0, label: '' });
    const [open, setOpen] = useState(false);
    const screenSize = useScreenSize();
    const [isVisible, setIsVisible] = useState(false);
    const CarrCatRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                setIsVisible(entries[0].isIntersecting);
            }
        });
        if (CarrCatRef.current) {
            observer.observe(CarrCatRef.current);
        }
        return () => observer.disconnect();
    }, []);

    const settings = {
        initialSlide: selectedCategoryList.length > 2 ? 1 : 0,
        infinite: selectedCategoryList.length > 2,
        slidesToShow: selectedCategoryList.length > 1 ? screenSize / 672 : 1,
        nextArrow: <ArrowButton />,
        prevArrow: <ArrowButton />,
        responsive: [
            {
                breakpoint: 960,
                settings: {
                    slidesToShow: 2,
                    dots: true,
                    centerMode: false,
                    initialSlide: 0,
                    infinite: false,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    infinite: false,
                    dots: true,
                },
            },
        ],
    };

    useEffect(() => {
        setTabs(extractUniqueKeyValues(cardsList, 'cat'));
        handleTabClick(cardsList[0].cat, 0);
    }, []);

    const handleTabClick = (tabName, index) => {
        let auxArray: any = [];
        cardsList.forEach((card) => {
            if (card.cat === tabName) {
                auxArray.push(card);
            }
        });
        setSelectedCat({ index: index, label: tabName });
        setSelectedCategoryList(auxArray);
        setOpen(false);
    };

    return (
        <Fade in={isVisible} timeout={1000}>
            <section ref={CarrCatRef}>
                <Box
                    className={`${styles.o_carruselcat} o_carruselcat`}
                    component="section"
                >
                    <Box className={styles.o_carruselcat__intro}>
                        {description && (
                            <Typography variant='body1' className="a-home__desc">{description}</Typography>
                        )}
                        {title && <h2 className="a-home__h2">{title}</h2>}
                    </Box>
                    {screenSize > 968 ? (
                        <Box className={styles.o_carruselcat__tabs}>
                            {tabs &&
                                tabs.map((tab, index) => (
                                    <button
                                        key={index}
                                        className={
                                            styles.o_carruselcat__tab +
                                            ` gtm_click_submenu_comfama_home_body_interes_${generateSlug(tab)} ${
                                                selectedCat.index === index
                                                    ? styles.o_carruselcat__tab__active
                                                    : ''
                                            }`
                                        }
                                        onClick={() =>
                                            handleTabClick(tab, index)
                                        }
                                    >
                                        {tab}
                                    </button>
                                ))}
                        </Box>
                    ) : (
                        <Box className={styles.o_carruselcat__dropdown}>
                            <button
                                onClick={() => setOpen(!open)}
                                className={styles.o_carruselcat__trigger}
                            >
                                <span>{selectedCat.label}</span>
                                <i className="icon-chevron-down1" />
                            </button>
                            {open && (
                                <button
                                    className={styles.o_carruselcat__options}
                                >
                                    {tabs.map((tab, index) => (
                                        <button
                                            key={tab}
                                            onClick={() =>
                                                handleTabClick(tab, index)
                                            }
                                            className={
                                                `${styles.o_carruselcat__option} gtm_click_submenu_comfama_home_body_interes_${generateSlug(tab)}`
                                            }
                                        >
                                            {tab}
                                        </button>
                                    ))}
                                </button>
                            )}
                        </Box>
                    )}
                    <Slider {...settings}>
                        {selectedCategoryList &&
                            selectedCategoryList.map(
                                (card: any, key: number) => (
                                    <Card
                                        key={`carruselCat-${key}`}
                                        className={styles.o_carruselcat__card}
                                    >
                                        <div
                                            className={
                                                styles.o_carruselcat__cardContainer
                                            }
                                            style={{
                                                color: card.color,
                                                background: card.bgColor,
                                            }}
                                        >
                                            <Box
                                                className={
                                                    styles.o_carruselcat__info
                                                }
                                            >
                                                <Typography variant='h3'
                                                    className={
                                                        styles.o_carruselcat__h3
                                                    }
                                                >
                                                    {card.title}
                                                </Typography>
                                                <p
                                                    className={
                                                        styles.o_carruselcat__p
                                                    }
                                                >
                                                    {card.desc}
                                                </p>
                                                <a
                                                    href={card.btnRedirect}
                                                    className={
                                                        `${styles.o_carruselcat__link} gtm_click_ecard_comfama_home_body_interes_${generateSlug(selectedCat.label.split(" ")[0])}_${generateSlug(card.title)}`
                                                    }
                                                    style={{
                                                        color: card.color,
                                                    }}
                                                    target={findTarget(
                                                        card.btnRedirect,
                                                    )}
                                                    rel={
                                                        findTarget(
                                                            card.btnRedirect,
                                                        ) === '_blank'
                                                            ? 'noreferrer follow'
                                                            : 'referrer follow'
                                                    }
                                                >
                                                    {card.btnTitle}
                                                </a>
                                            </Box>
                                            <img
                                                src={card.image}
                                                alt={card.title}
                                                className={
                                                    styles.o_carruselcat__img
                                                }
                                            />
                                        </div>
                                    </Card>
                                ),
                            )}
                    </Slider>
                    {screenSize > 968 && btnRedirect && (
                        <Box className={styles.o_carruselcat__btn}>
                            <Button2 label={btnTitle} redirect={btnRedirect} />
                        </Box>
                    )}
                </Box>
            </section>
        </Fade>
    );
};

export default CarruselCat;
